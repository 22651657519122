/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap'); */
/* @import url('./static/fonts/font-face.css'); */
@import url('//fonts.googleapis.com/earlyaccess/notosanskr.css');

body {
  margin: 0;
  font-family: 'NotoSansCJKkr', 'Noto Sans KR', 'Apple Color Emoji', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
}

a:visited {
  color: inherit;
}
a:hover {
  color: inherit;
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none !important;
}
