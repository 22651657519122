.ReceptionCancelModal .ant-modal-header {
  background: #1effc5;
  height: 83px;
  color: #000;
  font-size: 22px;
}
.ReceptionCancelModal .ant-modal-header .ant-modal-title {
  font-size: 22px;
  font-weight: bold;
  padding: 14px 16px;
}
.ReceptionCancelModal .ant-modal-body {
  padding: 33px 40px 0px;
}
.ReceptionCancelModal .ant-modal-close {
  right: -58px;
}
.ReceptionCancelModal .ant-modal-close .anticon {
  font-size: 30px;
  color: #fff;
}
.ReceptionCancelModal .ant-modal-footer {
  border-top: none;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 30px;
}
.ReceptionCancelModal .ant-modal-footer button {
  width: 108px;
  height: 42px;
  font-weight: bold;
}

.ReceptionCancelModal .ant-odal-footer button:nth-child(1) {
  color: #7c66de;
  font-size: 15px;
  background: #f9f9ff;
  border-color: #ebebeb;
}
.ReceptionCancelModal .ant-modal-footer button:nth-child(1) {
  color: #7c66de;
  border-color: #ebebeb;
}

.ReceptionCancelModal .ant-modal-footer button:nth-child(2) {
  color: #fff;
  font-size: 15px;
  background: #5834ff;
  border-color: #5834ff;
}
.ReceptionCancelModal .ant-modal-footer button:nth-child(2)[disabled] {
  background: #d6d3d3;
  color: #fff;
  border-color: #d6d3d3;
}

/* .ant-modal-close-x */
.ant-modal-close-x {
  background: url('../../icons/icon-modal-close.svg') no-repeat center center;
  background-position: 100% 5px;
}

@media only screen and (max-width: 640px) {
  .ant-modal {
    top: 70px;
  }
  .ReceptionCancelModal .ant-modal-close {
    display: inline-block;
    right: 0px;
    top: -35px;
  }
  .ReceptionCancelModal .ant-modal-close .ant-modal-close-x {
    background: url('../../icons/mo-modalclose.svg') no-repeat center center;
    width: 53px;
    height: 25px;
  }
  .ReceptionCancelModal .ant-modal-body {
    padding: 25px 20px 0px;
  }
}
